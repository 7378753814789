/* You can add global styles to this file, and also import other style files */
@import 'libs/shared/src/lib/utils/utils';
@import 'libs/shared/src/lib/utils/fonts';

html, body {
  height: 100%;
  background-color: #8568FE !important;
  margin: 0;
  padding: 0;
  font-family: 'Artegra', sans-serif;
}

h1,
h2,
h3 {
  font-family: 'Artegra-Bold';
}

.primary-color {
  color: #6e54fc !important;
}

.accent-color {
  color: #000000;
}

.warning-color {
  color: #ffcd1c;
}

.error-color {
  color: #fc4e69 !important;
}

.success-color {
  color: #389e0d;
}

p {
  b,
  strong {
    font-family: 'Artegra-Bold';
  }

  a {
    font-family: 'Artegra-Semibold';
  }
}

.defaultModal .ant-modal-content {
  min-height: 100vh;
}

// To onboarding modal.
.ant-notification {
  z-index: 999;
}

.cdk-virtual-scrollable::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: #f0f2f5 !important;
  border: 1px solid #f0f2f5 !important;
  border-radius: 8px !important;
}

.cdk-virtual-scrollable::-webkit-scrollbar {
  width: 5px !important;
}

.cdk-virtual-scrollable::-webkit-scrollbar-thumb {
  background: #6d54fc !important;
  border-radius: 8px !important;
}
