@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: 'Artegra';
  src: url('../../../../../libs/shared/src/lib/assets/fonts/artegra/Artegra_Sans-Alt-400-Regular.otf');
}

@font-face {
  font-family: 'Artegra-Medium';
  src: url('../../../../../libs/shared/src/lib/assets/fonts/artegra/Artegra_Sans-500-Medium.otf');
}

@font-face {
  font-family: 'Artegra-Bold';
  src: url('../../../../../libs/shared/src/lib/assets/fonts/artegra/Artegra_Sans-700-Bold.otf');
}

@font-face {
  font-family: 'Artegra-Semibold';
  src: url('../../../../../libs/shared/src/lib/assets/fonts/artegra/Artegra_Sans-600-SemiBold.otf');
}

@font-face {
  font-family: 'Poppins-Semibold';
  src: url('../../../../../libs/shared/src/lib/assets/fonts/poppins/Poppins-SemiBold.otf');
}

@font-face {
  font-family: 'Poppins';
  src: url('../../../../../libs/shared/src/lib/assets/fonts/poppins/Poppins-Regular.otf');
}

@font-face {
  font-family: 'Poppins-Thin';
  src: url('../../../../../libs/shared/src/lib/assets/fonts/poppins/Poppins-Thin.ttf');
}

@font-face {
  font-family: 'Poppins-Light';
  src: url('../../../../../libs/shared/src/lib/assets/fonts/poppins/Poppins-Light.ttf');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('../../../../../libs/shared/src/lib/assets/fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('../../../../../libs/shared/src/lib/assets/fonts/poppins/Poppins-Medium.otf');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('../../../../../libs/shared/src/lib/assets/fonts/poppins/Poppins-Bold.otf');
}